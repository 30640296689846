'use strict';

import I18n from './i18n';

import moment from 'moment';

export default {
  months: [
    'month_full_jan',
    'month_full_feb',
    'month_full_mar',
    'month_full_apr',
    'month_full_may',
    'month_full_jun',
    'month_full_jul',
    'month_full_aug',
    'month_full_sep',
    'month_full_oct',
    'month_full_nov',
    'month_full_dec',
  ],

  getDate: function (date) {
    return I18n.get('date_days', { days: date.getDate() });
  },

  getFullMonth: function (date) {
    return I18n.get(this.months[date.getMonth()]);
  },

  formatDateFullMonthWithoutYear: function (date) {
    if (I18n.getLanguage() === 'en') {
      return this.getFullMonth(date) + ' ' + moment(date).format('Do');
    } else {
      return this.getDate(date) + ' ' + this.getFullMonth(date).toLowerCase();
    }
  },

  formatDateFullMonth: function (date) {
    return this.getDate(date) + ' ' + this.getFullMonth(date).toLowerCase() + ' ' + date.getFullYear();
  },

  /**
   * Parses a datetime in YYYY-MM-DDTHH:MM:SS format.
   *
   * @param str
   * @returns {null|Date}
   */
  parseApiDateTime: function (str) {
    let reg = str.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})/);
    if (reg) {
      return new Date(
        parseInt(reg[1], 10),
        parseInt(reg[2], 10) - 1,
        parseInt(reg[3], 10),
        parseInt(reg[4], 10),
        parseInt(reg[5], 10),
        parseInt(reg[6],10),
        0
      );
    }
    return null;
  },

  /**
   * Transforms a date string into DD-MM-YYYY.
   *
   * @param str
   * @returns {string}
   */
  htmlDateToLocal: function (str) {
    let reg = str.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/);
    if (reg) {
      return reg[3] + '-' + reg[2] + '-' + reg[1];
    }
    return str;
  },

  /**
   * Transforms a date string into YYYY-MM-DD.
   *
   * @param str
   * @returns {string}
   */
  localToHtmlDate: function (str) {
    let reg = str.match(/^([0-9]{2})-([0-9]{2})-([0-9]{4})$/);
    if (reg) {
      return reg[3] + '-' + reg[2] + '-' + reg[1];
    }
    return str;
  }

};
